import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoute } from "@/AppRoute";
import { useUser } from "@/providers/useUser";
import { User } from "@/schemaTypes";

import { GetUserPersonalInfoDocument } from "./graphql";
import { IsProfilingDoneDocument } from "./Profiling/graphql";

const isPersonalInfoComplete = (user: Partial<User>): boolean => {
  const { profile = null } = user;
  if (profile === null) return false;
  return true;
};

const useOnBoarding = () => {
  const { user } = useUser();
  const { data: { user: userData } = {}, loading: isPersonalInfoLoading } =
    useQuery(GetUserPersonalInfoDocument, {
      fetchPolicy: "no-cache",
      variables: { userId: user?.id ?? "" },
    });
  const { data: { isProfilingDone } = {}, loading: isProfilingDoneLoading } =
    useQuery(IsProfilingDoneDocument, {
      fetchPolicy: "no-cache",
    });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //DEV NOTE: this part of code should be removed after deploy freemium
    //TODO: REMOVE THIS RETURN PLEASE
    return;
    /* const isUnboxingDone = !user?.unboxing; */
    if (!user || !userData || isPersonalInfoLoading || isProfilingDoneLoading) {
      return;
    }

    /*     if (!isUnboxingDone) {
      if (pathname !== AppRoute.Unboxing) {
        navigate(AppRoute.Unboxing);
      }
      return;
    } */

    const hasPersonalInfo = isPersonalInfoComplete(userData);
    const isProfilingComplete = hasPersonalInfo && isProfilingDone;
    if (!isProfilingComplete) {
      if (
        pathname !== AppRoute.Profiling &&
        pathname !== AppRoute.TermsAndConditions
      ) {
        //Descativar perfilamiento
        navigate(AppRoute.Profiling);
      }
      return;
    }

    if (
      [/*AppRoute.Unboxing,*/ AppRoute.Profiling].some(
        (path) => path === pathname
      )
    ) {
      navigate(AppRoute.Base);
      return;
    }

    if (pathname === AppRoute.Profiling) {
      navigate(AppRoute.Base);
      return;
    }
  }, [
    user,
    isPersonalInfoLoading,
    isProfilingDoneLoading,
    /* user?.unboxing, */
    pathname,
    userData,
    isProfilingDone,
  ]);
};

export { useOnBoarding };
