/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { User } from "firebase/auth";
import { config } from "../config";

let autoRefreshTokenTimer: NodeJS.Timeout | null = null;
const oneMinute = 1000 * 60;

const setAutoRefreshTimer = (tokenExpiresAt: string) => {
  if (tokenExpiresAt) {
    localStorage.setItem(config.JWT_EXPIRATION_KEY, tokenExpiresAt);
    const tokenExpiresAtMilis = parseInt(tokenExpiresAt, 10);

    if (!isNaN(tokenExpiresAtMilis)) {
      const tokenLifetime = tokenExpiresAtMilis - Date.now();
      if (autoRefreshTokenTimer) {
        clearTimeout(autoRefreshTokenTimer);
      }

      const autoRefreshTime =
        tokenLifetime - oneMinute > 0 ? tokenLifetime - oneMinute : oneMinute;

      autoRefreshTokenTimer = setTimeout(() => {
        // Schedule a next refresh based on token expiration
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        refreshToken();
      }, autoRefreshTime);
    }
  }
};

export const refreshToken = async (
  cleanTokenIfFailed = false
): Promise<void> => {
  const token = localStorage.getItem(config.RT_KEY);

  if (token) {
    const refreshTokenResponse = await fetch(`${config.AUTH_API_URL}/refresh`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refreshToken: token }),
      credentials: "include",
    });

    if (refreshTokenResponse.status !== 200) {
      if (cleanTokenIfFailed) {
        localStorage.removeItem(config.RT_KEY);
      }
      throw new Error("Error refreshing token");
    }

    const tokenResponse = await refreshTokenResponse.json();

    if (tokenResponse.refreshToken) {
      localStorage.setItem(config.RT_KEY, tokenResponse.refreshToken);
    }

    if (tokenResponse.token) {
      localStorage.setItem(config.JWT_KEY, tokenResponse.token);
      setAutoRefreshTimer(tokenResponse.expiresAt);
    } else {
      if (cleanTokenIfFailed) {
        localStorage.removeItem(config.RT_KEY);
      }
      throw new Error("Error parsing response token");
    }
  } else {
    throw new Error("Invalid refresh token");
  }
};

export const exchangeToken = async (
  firebaseUser: User,
  idToken: string,
  token?: string
): Promise<void> => {
  const exchangeResponse = await fetch(`${config.AUTH_API_URL}/exchange`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ firebaseUser, idToken, token }),
    credentials: "include",
  });

  if (exchangeResponse.status !== 200) {
    const json = await exchangeResponse.json();
    throw new Error(`Error Logging in ${json.message}`);
  }

  const tokenResponse = await exchangeResponse.json();

  if (tokenResponse.refreshToken) {
    localStorage.setItem(config.RT_KEY, tokenResponse.refreshToken);
  }

  if (tokenResponse.token) {
    localStorage.setItem(config.JWT_KEY, tokenResponse.token);
    setAutoRefreshTimer(tokenResponse.expiresAt);
  } else {
    throw new Error("Error parsing response token");
  }
};

export const signInWithCredentials = async (
  email: string,
  password: string
): Promise<void> => {
  const signInResponse = await fetch(`${config.AUTH_API_URL}/sign-in`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
    credentials: "include",
  });

  if (signInResponse.status !== 200) {
    const json = await signInResponse.json();
    throw new Error(`Error Logging in: ${json.message}`);
  }

  const tokenResponse = await signInResponse.json();

  if (tokenResponse.refreshToken) {
    localStorage.setItem(config.RT_KEY, tokenResponse.refreshToken);
  }

  if (tokenResponse.token) {
    localStorage.setItem(config.JWT_KEY, tokenResponse.token);
    setAutoRefreshTimer(tokenResponse.expiresAt);
  } else {
    throw new Error("Error in response token");
  }
  if (tokenResponse.responseGamification !== null) {
    localStorage.setItem(
      "responseGamification",
      JSON.stringify(tokenResponse.responseGamification)
    );
  }
};

export const signUpWithCredentials = async ({
  name,
  email,
  password,
  clientToken,
  domain,
  token,
  returnURL,
}: {
  name: string;
  email: string;
  password: string;
  clientToken: string;
  domain: string;
  token?: string;
  returnURL?: string;
}): Promise<void> => {
  const signUpRequest = await fetch(`${config.AUTH_API_URL}/sign-up`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email,
      password,
      name,
      clientToken,
      domain,
      token,
      returnURL,
    }),
    credentials: "include",
  });

  if (signUpRequest.status !== 200) {
    const json = await signUpRequest.json();
    throw new Error(`Error Signing up: ${json.message}`);
  }

  const signUpResponse = await signUpRequest.json();

  if (!signUpResponse.id) {
    throw new Error("Error registering user");
  }
};

export const signOut = async (): Promise<void> => {
  const token = localStorage.getItem(config.RT_KEY);
  localStorage.removeItem(config.JWT_KEY);
  localStorage.removeItem(config.RT_KEY);

  if (autoRefreshTokenTimer) {
    clearTimeout(autoRefreshTokenTimer);
  }

  const signOutResponse = await fetch(`${config.AUTH_API_URL}/sign-out`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ token }),
  });

  if (signOutResponse.status !== 200) {
    throw new Error(`Error signning out: ${signOutResponse.statusText}`);
  }
};

export const validateEmail = async (token: string): Promise<void> => {
  const validateRequest = await fetch(`${config.AUTH_API_URL}/validate-email`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
    credentials: "include",
  });

  if (validateRequest.status !== 200) {
    const json = await validateRequest.json();
    throw new Error(`Error Validating email: ${json.message}`);
  }

  const validateResponse = await validateRequest.json();

  if (!validateResponse.id) {
    throw new Error("Error validating email");
  }
};

export const resetPassword = async (
  passwordToken: string,
  password: string,
  clientToken: string
): Promise<void> => {
  const signUpRequest = await fetch(`${config.AUTH_API_URL}/reset-password`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ passwordToken, password, clientToken }),
    credentials: "include",
  });

  if (signUpRequest.status !== 200) {
    const json = await signUpRequest.json();
    throw new Error(`Error Resetting password: ${json.message}`);
  }
};

export const forgotPassword = async (
  email: string,
  clientToken: string,
  domain: string
): Promise<void> => {
  const signUpRequest = await fetch(`${config.AUTH_API_URL}/forgot-password`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, clientToken, domain }),
    credentials: "include",
  });

  if (signUpRequest.status !== 200) {
    const json = await signUpRequest.json();
    throw new Error(`Error Resetting password: ${json.message}`);
  }
};

export const validatePasswordToken = async (
  passwordToken: string,
  recaptchaToken: string
): Promise<void> => {
  const validateRequest = await fetch(
    `${config.AUTH_API_URL}/verify-password-token-valid`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ passwordToken, recaptchaToken }),
      credentials: "include",
    }
  );

  if (validateRequest.status !== 200) {
    const json = await validateRequest.json();
    throw new Error(json.message);
  }
};
