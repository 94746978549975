import { useContext } from "react";
import { learnContext } from "../providers/learnProvider";
import { Box, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { BackButton } from "@/components/PageContainer/BackButton";
import { useUniqueCategories } from "./hooks";
import { Ico } from "@/assets/icons";

const SubCategoryFilter = () => {
  const isMobile = useBreakpointValue({ base: true, xl: false });
  const showIcon = false;
  const { selectedBrand, setSelectedSubCategory, selectedSubCategory } =
    useContext(learnContext);

  const uniqueCategories = useUniqueCategories() || [];

  const shouldRenderSubCategories = uniqueCategories.length > 0;

  const handleSelectSubCategories = (category: string) => {
    if (category === selectedSubCategory) return setSelectedSubCategory("");

    setSelectedSubCategory(category);
  };

  return (
    <Box w={"100%"}>
      {!isMobile && <BackButton to={-1} text="Back" />}
      <Heading as={"h4"} mt={"16px"}>
        {`${selectedBrand?.description} ${selectedBrand?.name}`}
      </Heading>

      {shouldRenderSubCategories ? (
        <Box>
          <Text variant={"caption"} mt={4} mb={3}>
            Filtra por modelo de negocio:
          </Text>
          <Flex gap={3} flexDir={"row"} flexWrap={"wrap"}>
            {uniqueCategories?.map((category) => (
              <Flex
                key={category}
                border="1px solid"
                borderColor="neutral.100"
                py={2}
                px={3}
                borderRadius="8px"
                maxH={"32px"}
                maxW={"fit-content"}
                textAlign="initial"
                onClick={() => handleSelectSubCategories(category ?? "")}
                bg={
                  category === selectedSubCategory ? "secondary.400" : undefined
                }
                alignItems={"center"}
                gap={2}
                cursor={"pointer"}
                _hover={{
                  bg:
                    category === selectedSubCategory
                      ? "secondary.400"
                      : "neutral.100",
                }}
              >
                {showIcon && <Ico.Amazon />}
                <Text
                  variant={"bold"}
                  fontSize={"14px"}
                  w={"fit-content"}
                  color={category === selectedSubCategory ? "white" : undefined}
                >
                  {category}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
};

export default SubCategoryFilter;
