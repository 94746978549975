import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { GetBannerToContinueProgressDocument } from "@/components/ContinueToProgress/graphql/bannerContinueToProgress.generated";
import {
  BannerContinueProgress,
  CourseBannerProgress,
  EntityName,
  InteractiveContentBannerContinueProgress,
  ProgramBannerProgress,
} from "@/schemaTypes";
import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Progress,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BigSpinner } from "../BigSpinner";
import { t } from "i18next";

export const ContinueProgress = () => {
  const navigate = useNavigate();
  const [infoBannerState, setinfoBannerState] = useState<
    BannerContinueProgress | undefined
  >();
  const [interactiveContentBanner, setinteractiveContentBanner] =
    useState<InteractiveContentBannerContinueProgress>();
  const [Courses, setCourses] = useState<CourseBannerProgress>();
  const [programs, setprograms] = useState<ProgramBannerProgress>();
  const [
    getBannerToContinueProgress,
    { data: infoBannerContinueToProgress, loading },
  ] = useLazyQuery(GetBannerToContinueProgressDocument);

  const getThumbnail = (infoBanner: BannerContinueProgress | undefined) => {
    if (
      infoBanner &&
      infoBanner.course?.courseType === EntityName.AdvancedCourse
    ) {
      return infoBanner.course.imageUrl;
    } else {
      return infoBanner?.program?.thumbnailUrl;
    }
  };

  useEffect(() => {
    getBannerToContinueProgress({
      fetchPolicy: "network-only",
    });

    return () => {
      setinfoBannerState(undefined);
      setCourses(undefined);
      setinteractiveContentBanner(undefined);
      setprograms(undefined);
    };
  }, []);

  useEffect(() => {
    if (infoBannerContinueToProgress?.getBannerToContinueProgress) {
      setinfoBannerState(
        infoBannerContinueToProgress.getBannerToContinueProgress as BannerContinueProgress
      );
      setCourses(
        infoBannerContinueToProgress.getBannerToContinueProgress
          .course as CourseBannerProgress
      );
      setinteractiveContentBanner(
        infoBannerContinueToProgress.getBannerToContinueProgress
          .interactiveContent as InteractiveContentBannerContinueProgress
      );
      setprograms(
        infoBannerContinueToProgress.getBannerToContinueProgress
          .program as ProgramBannerProgress
      );
    }
  }, [infoBannerContinueToProgress]);

  const goToTheProgress = (
    program: ProgramBannerProgress | undefined,
    course: CourseBannerProgress | undefined
  ) => {
    if (course?.courseType === EntityName.AdvancedCourse) {
      navigate(
        `${AppRoute.AdvancedCourse}/${course.id}${AppRoute.Cohorts}/${course.cohortsId}${AppRoute.Sessions}/${interactiveContentBanner?.sessionId}${AppRoute.InteractiveContent}`
      );
    } else {
      if (!program || !course) return;
      navigate(
        `${AppRoute.Programs}/${program.id}${AppRoute.Courses}/${course.id}${AppRoute.Cohorts}/${course.cohortsId}${AppRoute.Sessions}/${interactiveContentBanner?.sessionId}${AppRoute.InteractiveContent}`
      );
    }
  };

  return (
    <>
      {loading ? (
        <Box h={10} display={"flex"} justifyContent={"center"} w={"100%"}>
          <BigSpinner />
        </Box>
      ) : (
        <>
          <Flex
            hidden={!infoBannerState?.showBanner}
            width={"95%"}
            gap={6}
            w={"100%"}
            py={"16px"}
            px={{ base: "16px", lg: "24px" }}
            backgroundColor={"neutral.50"}
            borderRadius={"8px"}
            cursor={"pointer"}
            alignItems={"center"}
            onClick={() => goToTheProgress(programs, Courses)}
          >
            <Box
              width={"250px"}
              height={"100%"}
              position="relative"
              alignContent={"center"}
            >
              <Image
                w={"100%"}
                // height={"100%"}
                objectFit={{ base: "contain", lg: "cover" }}
                borderRadius={"8px"}
                backgroundColor="rgba(0, 0, 0, 1)"
                src={getThumbnail(infoBannerState) ?? ""}
                _after={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  bg: "black",
                  opacity: 0.3,
                  borderRadius: "8px",
                }}
              />
              <IconButton
                pl={1}
                aria-label="Play Button"
                icon={<Ico.PlayFill />}
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                height={"40px"}
                width={"40px"}
                background="primary.300"
                fontSize="large"
              />
            </Box>
            <Flex w={"95%"} flexDirection={"column"} gap={1}>
              <Box>
                <Text
                  variant={{ base: "feedback", lg: "bold" }}
                  fontWeight={{
                    base: "600 !important",
                  }}
                >
                  {t("Continue your Progress:")}
                </Text>
              </Box>
              <Box fontSize={"14px"}>
                <Text variant={{ base: "feedback", lg: "caption" }}>
                  {interactiveContentBanner?.title || ""}
                </Text>
                <Flex
                  align={"center"}
                  justify={"center"}
                  w={{ base: "90%", lg: "30%" }}
                  gap={"16px"}
                >
                  <Progress
                    my={"10px"}
                    colorScheme={"secondary"}
                    flexGrow={1}
                    h={"5px"}
                    rounded={"4px"}
                    value={infoBannerState?.progress || 0}
                  />
                  <Text
                    variant={"feedback"}
                  >{`${infoBannerState?.progress}%`}</Text>
                </Flex>
              </Box>
            </Flex>
            <Box width={"5%"}>
              <Flex
                w={"100%"}
                height={"100%"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                padding={5}
              >
                <Icon as={Ico.ChevronRight} fontSize={"12px"} w={8} h={8} />
              </Flex>
            </Box>
          </Flex>
        </>
      )}
    </>
  );
};
