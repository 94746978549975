import { differenceInSeconds } from "date-fns";

export function calculateTimeAgo(createdAt: Date) {
  if (createdAt) {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffSeconds = differenceInSeconds(now, createdDate);

    if (diffSeconds < 60) {
      return `Hace ${diffSeconds} seg`;
    } else {
      const diffMinutes = Math.floor(diffSeconds / 60);

      if (diffMinutes < 60) {
        return `Hace ${diffMinutes} min`;
      } else {
        const diffHours = Math.floor(diffMinutes / 60);

        if (diffHours < 24) {
          return `Hace ${diffHours} h`;
        } else {
          const diffDays = Math.floor(diffHours / 24);

          if (diffDays < 30) {
            return `Hace ${diffDays} d`;
          } else {
            const diffMonths = Math.floor(diffDays / 30);

            if (diffMonths < 12) {
              return `Hace ${diffMonths} mes${diffMonths > 1 ? "es" : ""}`;
            } else {
              const diffYears = Math.floor(diffMonths / 12);
              return `Hace ${diffYears} año${diffYears > 1 ? "s" : ""}`;
            }
          }
        }
      }
    }
  }
  return "";
}

export function getHighestRole(roles: string[]): string {
  const hierarchy: { [key: string]: number } = {
    ADMIN: 3,
    MENTOR: 2,
    TUTOR: 1,
  };
  let highestRole: string | null = null;
  let highestValue: number = 0;
  let firstRole: string | null = null;

  for (const role of roles) {
    if (firstRole === null) {
      firstRole = role;
    }

    if (hierarchy[role] > highestValue) {
      highestValue = hierarchy[role];
      highestRole = role;
    }
  }

  return highestRole ? highestRole : firstRole || "";
}

export const validateRole = (role: string) => {
  const arrayRoles = ["ADMIN", "MENTOR", "TUTOR"];
  return arrayRoles.includes(role);
};

export const platforms = [
  "Amazon",
  "Arbitraje",
  "Dropshipping",
  "Facebook Marketplace",
  "Instagram",
  "Marca Privada",
  "Mercado Libre",
  "Redes Sociales",
  "Shopify",
  "TikTok",
  "Tienda Física",
  "Tiendanube",
  "Walmart",
  "Whatsapp",
  "Wholesale",
  "Otros",
];
